@use "../../styles/abstract/_mixins.scss" as *;
@use "../../styles/abstract/_variables.scss" as *;

.container {
	height: 100vh;
	width: 100vw;
	background-color: $primary;
	margin-right: 8rem;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
    overflow: hidden;

	&__bg {
		width: 100%;
		height: 100%;
		z-index: 1;
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		bottom: 0;
        overflow: hidden;
	}

	&__layer {
		width: 100%;
		height: 100%;
		z-index: 2;
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
	}
}
