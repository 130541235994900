@use "../../styles/abstract/_mixins.scss" as *;
@use "../../styles/abstract/_variables.scss" as *;

.container {
	background-color: $gold;
	display: fixed !important;
	background: transparent;
	width: 100vw;
	height: 100vh;
	z-index: 99;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;

	&__secondary-colour {
		display: fixed !important;
		width: 100vw;
		height: 100vh;
		background-color: $gold;
		z-index: -1;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}

	&__layer {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100vw;
		height: 100vh;
		background-color: $primary;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}

	&__link {
		&__hidden-layer {
			overflow: hidden;
			--bg-h: 2px;

			a {
				font-size: 3rem;
				line-height: 80px;
				font-family: "Kaftan, sans-serif";
				font-style: normal;
				font-weight: 400;
				width: auto;
				color: $white;
				-webkit-text-fill-color: transparent;
				-webkit-text-stroke-width: 1px;
				-webkit-text-stroke-color: $white;
				background: linear-gradient(0deg, $white, $white) no-repeat right bottom / 0
					var(--bg-h);
				background-clip: text;
				transition: background-size 350ms cubic-bezier(0.22, 0.61, 0.36, 1);
				--bg-h: 100%;
				background-position: left;
				padding: 1.5rem 0;
				position: relative;

				&:hover,
				&:focus {
					background-size: 100% var(--bg-h);
					background-position-x: left;
				}
			}
		}

		&__active {
			background-size: 100% var(--bg-h) !important;
			background-position-x: left !important;
		}
	}
}
