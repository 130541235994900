@use "../../styles/abstract/_mixins.scss" as *;
@use "../../styles/abstract/_variables.scss" as *;

.header {
	width: 100%;
	height: 70px !important;
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: transparent;
	padding: 10px 50px;
	position: fixed;
	top: 1rem;
	left: 0;
	right: 0;
	z-index: 100;
	mix-blend-mode: difference;

	@include respond(phone) {
		padding: 10px 15px;
	}

	.menu-button {
		padding: 0.5rem;

		&:focus {
			border: 1px dotted $white;
			border-radius: 50%;
		}

		.hamburger {
			display: none;
			cursor: pointer;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			width: 32px;
			height: 20px;
			position: relative;
			z-index: 100;

			@include respond(phone) {
				padding: 0rem;
			}

			.lines {
				display: block;
				width: 30px;
				height: 2px;
				background-color: $white;
				border-radius: 5px;
				transition: all 1s cubic-bezier(0.79, 0.14, 0.15, 0.86);

				&::after,
				&::before {
					content: "";
					position: absolute;
					width: 30px;
					border-radius: 5px;
					height: 2px;
					background-color: $white;
					transition: all 1s cubic-bezier(0.79, 0.14, 0.15, 0.86);
				}

				&::after {
					transform: translate(-15px, 5px);
				}

				&::before {
					transform: translate(-15px, -5px);
				}
			}

			.open.lines {
				background-color: transparent;

				&::after {
					transform: rotate(45deg) translate(5px, -5px);
				}

				&::before {
					transform: rotate(-45deg) translate(5px, 5px);
				}
			}
		}
	}
}
