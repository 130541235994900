.container {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: flex-end;
	cursor: pointer;
	width: auto;

	svg {
		width: 100%;
		height: auto;
		max-height: 20px;
		max-width: 70px;
	}

	p {
		font-family: "Kaftan, sans-serif";
		font-style: normal;
		font-weight: 400;
		font-size: 1rem;
		text-align: right;
		color: #fff;
	}
}
