@use "../../styles/abstract/_variables.scss" as *;
@use "../../styles/abstract/_mixins.scss" as *;

.scroll-container {
	position: fixed;
	left: 0;
	right: 0;
	will-change: transform;

	.ghost {
		width: 100vw;
	}
}

.children-container {
	position: relative;
	height: 100vh;
	width: max-content;
	display: flex;
	align-items: center;
	background-color: $white;

	.children {
		position: relative;
		display: flex;
		border: 20px solid $black;

		& > *:not(:last-child) {
			margin-right: 45px;
		}

		.child {
			height: 40vh;
			width: 700px;
			background-color: $black;
			border: 15px solid $white;
		}
	}
}
